function isVisible(elem: HTMLElement) {
  return !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
}

function isHidden(elem: HTMLElement) {
  return !isVisible(elem);
}

function show(elem: HTMLElement): void;
function show(selector: string, root?: HTMLElement | Document): void;
function show(elOrSelector: HTMLElement | string, root: HTMLElement | Document = document): void {
  if (elOrSelector instanceof HTMLElement) {
    elOrSelector.style.display = "block";
  } else if (typeof elOrSelector === "string") {
    const nodes = jqSelect<HTMLElement>(elOrSelector, root);
    nodes.forEach((node) => show(node));
  }
}

function hide(elem: HTMLElement): void;
function hide(selector: string, root?: HTMLElement | Document): void;
function hide(elOrSelector: HTMLElement | string, root: HTMLElement | Document = document): void {
  if (elOrSelector instanceof HTMLElement) {
    elOrSelector.style.display = "none";
  } else if (typeof elOrSelector === "string") {
    const nodes = jqSelect<HTMLElement>(elOrSelector, root);
    nodes.forEach((node) => hide(node));
  }
}

function toElement(html: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  return doc.body.firstChild as HTMLElement;
}

function text(selector: string, root: HTMLElement | Document = document) {
  const nodes = jqSelect<HTMLElement>(selector, root);
  return nodes.map((node) => node.innerText).join("");
}

function insertStyles(styles: string) {
  const styleElement = document.createElement("style");
  styleElement.textContent = styles;
  document.head.appendChild(styleElement);
}

function addCssToHead(css_type: string, css_contents: string) {
  const id = `tradepending-css-${css_type}`;
  const css = `<style id='${id}'>${css_contents}</style>`;
  const styleElement = document.getElementById(id);
  if (styleElement) {
    styleElement.outerHTML = css;
  } else {
    document.head.insertAdjacentHTML("beforeend", css);
  }
}

function addCssLinkToHead(css_type: string, css_url: string) {
  const id = `tradepending-css-${css_type}`;
  const existingLinkElement = document.getElementById(id);

  const linkElement = document.createElement("link");
  linkElement.id = id;
  linkElement.href = css_url;
  linkElement.rel = "stylesheet";
  linkElement.type = "text/css";
  linkElement.media = "screen";

  if (existingLinkElement) {
    document.head.replaceChild(linkElement, existingLinkElement);
  } else {
    document.head.prepend(linkElement);
  }
}

function remove(selector: string, root: HTMLElement | Document = document) {
  const elements = jqSelect(selector, root);
  for (const element of Array.from(elements)) {
    element.remove();
  }
}

function documentWidth() {
  return document.documentElement.scrollWidth;
}

function windowWidth() {
  return window.innerWidth;
}

// Takes a jquery selector and converts it to something that works with document.querySelector(...)
function jqSelect<T extends Element>(selector: string, root: HTMLElement | Document = document): T[] {
  selector = selector?.trim();
  if (!selector) {
    return [];
  }

  // If there's a selector like "div:first, span:first", we have no choice but to process them individually
  if (selector.match(/(:first|:last).*?,/)) {
    const parts = selector.split(",");
    const result = parts.reduce((acc, segment) => {
      return acc.concat(jqSelect(segment));
    }, []);
    return result;
  }

  selector = escapeNumericIds(selector);

  // Handle :first somewhere in the middle, e.g. "div:first span" or "div:first > span".
  // If it's >, we need to add :scope to the front of the selector
  if (selector.indexOf(":first ") > -1) {
    const base = selector.substring(0, selector.indexOf(":first "));
    let end = selector.substring(selector.indexOf(":first ") + 7);
    const baseNodes = jqSelect<HTMLElement>(base, root);
    if (!baseNodes.length) {
      return [];
    }
    if (end.trim().startsWith(">")) {
      end = ":scope " + end;
    }
    return jqSelect(end, baseNodes[0]);
  }

  // Handle :last somewhere in the middle, e.g. "div:last span" or "div:last > span".
  // If it's >, we need to add :scope to the front of the selector
  if (selector.indexOf(":last ") > -1) {
    const base = selector.substring(0, selector.indexOf(":last "));
    let end = selector.substring(selector.indexOf(":last ") + 6);
    const baseNodes = jqSelect<HTMLElement>(base, root);
    if (!baseNodes.length) {
      return [];
    }
    if (end.trim().startsWith(">")) {
      end = ":scope " + end;
    }
    return jqSelect(end, baseNodes[baseNodes.length - 1]);
  }

  if (selector.endsWith(":first")) {
    const match = root.querySelector<T>(selector.slice(0, -6));
    if (match) {
      return [match];
    }
    return [];
  }
  if (selector.endsWith(":last")) {
    const elements = root.querySelectorAll<T>(selector.slice(0, -5));
    if (elements.length) {
      return [elements[elements.length - 1]];
    }
    return [];
  }
  if (selector.endsWith(":even")) {
    const elements = root.querySelectorAll<T>(selector.slice(0, -5));
    return Array.from(elements).filter((_, i) => i % 2 === 0);
  }
  if (selector.endsWith(":odd")) {
    const elements = root.querySelectorAll<T>(selector.slice(0, -4));
    return Array.from(elements).filter((_, i) => i % 2 === 1);
  }

  // If it ends in :nth(x), do the selector and then return the nth match
  const nthRegex = /:nth\((\d+)\)/;
  if (selector.match(nthRegex)) {
    const match = selector.match(nthRegex);
    const num = match ? parseInt(match[1], 10) : null;
    const elements = root.querySelectorAll<T>(selector.replace(nthRegex, ""));
    if (num < elements.length) {
      return [elements[num]];
    }
    return [];
  }

  return Array.from(root.querySelectorAll(selector));
}

// Numeric IDs need to be escaped in CSS selectors.
// A selector with a numeric ID like div#1234 should actually be div#\31 234
function escapeNumericIds(selector: string) {
  const regex = /#(\d)(\w*)/g;

  const escapedSelector = selector.replace(regex, (match, digit, restOfId) => {
    return `#\\3${digit} ${restOfId}`;
  });

  return escapedSelector;
}

const domUtils = {
  toElement,
  isVisible,
  isHidden,
  hide,
  show,
  text,
  remove,
  jqSelect,
  insertStyles,
  addCssLinkToHead,
  windowWidth,
  documentWidth,
  addCssToHead,
};

export default domUtils;
